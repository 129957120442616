import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    allowGoogleMaps: localStorage.getItem("allow-google-maps") === "true",
  },
  getters: {
    allowGoogleMaps: (state) => state.allowGoogleMaps,
  },
  mutations: {
    allowMap(state) {
      localStorage.setItem("allow-google-maps", "true");
      state.allowGoogleMaps = true;
    },
    revokewMap(state) {
      localStorage.removeItem("allow-google-maps");
      state.allowGoogleMaps = false;
    },
  },
  actions: {},
  modules: {},
});
