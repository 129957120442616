import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/home.vue"),
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () =>
      import(
        /* webpackChunkName: "impressum" */ "@/views/impressum/impressum.vue"
      ),
  },
];

export default routes;
